import React from "react";
import { StrictMode } from "react";
import "./styles.css";

function Navbar() {
	return (
		<nav>
  			<div>
  				<a href="/" className="logo"></a>
  				<a href="/" className="title">Connector</a>
  			</div>
  		</nav>
	);
}

function Footer() {
	return (
		<footer>&copy; Copyright Expansion Games. All rights reserved. <span>Contact us: <a href="mailto:hello@expansion.games" className="href-email">hello@expansion.games</a></span></footer>
	);
}

function Home() {
  return (
	  <main>
		  <div className="love"></div>
		  <h1>Connector is no more!</h1>
		  <p>It’s been fun but it came to an end.</p>
		  <p>To all the Connector users out there, thanks for supporting us!</p>
		  <p>We’re now busy with <span><a href="https://expansionbeyond.com">something totally different.</a></span></p>
	  </main>
  );
}

function NotFound() {
  return (
	  <main>
		  <div className="bulldog"></div>
		  <h1>Not found</h1>
		  <p>We couldn't find that page, but <span><a href="/">here's our home.</a></span></p>
	  </main>
  );
}

export class PageHome extends React.Component {
	render() {
		return (
			<StrictMode>
				<Navbar />
				<Home />
				<Footer />
			</StrictMode>
		);
	}
}

export class PageNotFound extends React.Component {
	render() {
		return (
			<StrictMode>
				<Navbar />
				<NotFound />
				<Footer />
			</StrictMode>
		);
	}
}
